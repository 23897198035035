import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Spin, Typography } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { useBoolean } from 'hooks/useBoolean';

import { AnnouncementCard } from '../AnnouncementCard';
import { CreateAnnouncementModal } from '../createAnnouncementModal/CreateAnnouncementModal';
import { AnnouncementType } from '../createAnnouncementModal/types';
import { useGetAnnouncementsForCareProvider } from '../hooks';

const MultiAnnouncementWrapper = styled.div`
  > *:not(:first-child) {
    margin-top: 16px;
  }
`;

export const CareProviderAnnouncements = () => {
  const { careProviderId } = useParams<{ careProviderId?: string }>();

  const { data: announcementsCareProviderResult, isLoading } =
    useGetAnnouncementsForCareProvider(careProviderId);

  const activeCareProviderAnnouncement = announcementsCareProviderResult?.data.careProvider;
  const activeCareUnitAnnouncements = announcementsCareProviderResult?.data.careUnits;

  const isCreateAnnouncementModalOpen = useBoolean(false);

  return (
    <>
      <Typography.Title level={2}>
        <FormattedMessage id="care-units.announcements" />
      </Typography.Title>

      <Typography.Paragraph>
        <FormattedMessage id="Notify healthcare personnel about critical information. Announcements are displayed at the top of the Clinic interface. There can be up to two active: one for the entire care provider and one for specific care units." />
      </Typography.Paragraph>

      <Typography.Title level={3}>
        <FormattedMessage id="Active announcements" />
      </Typography.Title>

      {isLoading ? (
        <Spin size="large" />
      ) : (
        <>
          {/* Care provider */}
          <Card
            style={{ maxWidth: 824 }}
            title={<FormattedMessage id="Visible to the entire care provider" />}
          >
            {activeCareProviderAnnouncement ? (
              <AnnouncementCard
                announcement={activeCareProviderAnnouncement}
                type={AnnouncementType.CareProvider}
              />
            ) : (
              <>
                <Typography.Title level={4}>
                  <FormattedMessage id="No active announcement" />
                </Typography.Title>
                <Button onClick={isCreateAnnouncementModalOpen.setTrue} type="primary">
                  <PlusOutlined /> <FormattedMessage id="Create announcement" />
                </Button>
              </>
            )}
          </Card>

          <br />

          {/* Care units */}
          <Card
            style={{ maxWidth: 824 }}
            title={<FormattedMessage id="Visible to individual care units" />}
          >
            {!activeCareUnitAnnouncements?.length && (
              <Typography.Title level={4}>
                <FormattedMessage id="No active announcement" />
              </Typography.Title>
            )}

            <MultiAnnouncementWrapper>
              {activeCareUnitAnnouncements?.length ? (
                <>
                  {activeCareUnitAnnouncements.map(announcement => (
                    <AnnouncementCard
                      key={announcement.id}
                      announcement={announcement}
                      linkToActionablePage={`/health-care-organizations/${careProviderId}/care-units/${announcement.careUnitId}/announcements`}
                      type={AnnouncementType.CareUnit}
                    />
                  ))}
                </>
              ) : (
                <FormattedMessage id="Create an announcement for a specific care unit by going to the unit's settings and adding the announcement there." />
              )}
            </MultiAnnouncementWrapper>
          </Card>
        </>
      )}

      {!activeCareProviderAnnouncement && !!careProviderId && (
        <CreateAnnouncementModal
          careProviderId={careProviderId}
          handleCloseModal={isCreateAnnouncementModalOpen.setFalse}
          open={isCreateAnnouncementModalOpen.value}
          type={AnnouncementType.CareProvider}
        />
      )}
    </>
  );
};
