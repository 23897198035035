import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { styled } from 'styled-components';

import logo from 'assets/svg/manage24Logo.svg';
import logoCollapsed from 'assets/svg/p24_logo_small.svg';

import { MainNavigationMenuItem } from './MainNavigationMenuItem';
import { useIsMainNavigationCollapsed } from '../MainNavigationContext';

export const MainMenuLogoBoring = () => {
  const intl = useIntl();
  return (
    <MainNavigationMenuItem
      collapsedView={<img src={logoCollapsed} alt={intl.formatMessage({ id: 'general.logo' })} />}
      antiLabel={<img src={logo} alt={intl.formatMessage({ id: 'general.logo' })} />}
    />
  );
};

export const MainMenuLogo = () => {
  const isCollapsed = useIsMainNavigationCollapsed();
  const intl = useIntl();
  const history = useHistory();
  const goHome = useCallback(() => {
    history.push('/');
  }, [history]);
  return (
    <PositioningWrapper onClick={goHome} role="link">
      <MainLogo src={logo} alt={intl.formatMessage({ id: 'general.logo' })} />
      <CollapsedLogo $collapsed={isCollapsed}>
        <img src={logoCollapsed} role="presentation" />
      </CollapsedLogo>
    </PositioningWrapper>
  );
};

const PositioningWrapper = styled.div`
  position: relative;
  padding-left: 16px;
  overflow: hidden;
  cursor: pointer;
  min-height: 27px;
  flex: 0 1 42px;
`;

const MainLogo = styled.img``;

const CollapsedLogo = styled.div<{ $collapsed: boolean }>`
  position: absolute;

  background-color: white;

  top: -2px;
  left: 0;
  transition: transform 0.3s ease;

  width: 100px;
  height: 100px;

  transform: translateX(${props => (props.$collapsed ? 14 : 88)}px);
  padding-left: ${props => (props.$collapsed ? 4 : 0)}px;

  img {
    transform: scale(${props => (props.$collapsed ? 1 : 0.88)});
    transition: transform 0.3s ease;
  }
`;
