import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Space, Form, Select, Tooltip, Popover } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DefaultEmpty } from './emptyState/DefaultEmpty';
import styles from './filters.module.css';
import { useCareUnitsAndCareProviders } from '../hooks/useCareProvidersAndCareUnitsFilter';
import { useRoleFilter } from '../hooks/useRoleFilter';
import { FilterProps, UserFilter } from '../types';

const { Search } = Input;

interface Props {
  onFilter: (event: FilterProps) => void;
  initialValues: UserFilter;
  onClear: () => void;
}

interface DisplayValueType {
  label: string;
}
const maxTagPlaceholder = (props: unknown) => {
  const displayValueType = props as DisplayValueType[];
  return (
    <Tooltip
      overlayStyle={{ pointerEvents: 'none' }}
      title={displayValueType.map(({ label }: { label: string }) => label).join(', ')}
    >
      <span>+ {displayValueType.length} ...</span>
    </Tooltip>
  );
};

const Filters = (props: Props) => {
  const intl = useIntl();
  const { initialValues } = props;
  const [showPopover, setShowPopover] = React.useState(false);
  const [searchString, setSearchString] = React.useState(initialValues.searchString || '');
  const {
    practitionerRoles,
    administrativeRoles,
    selectedAdministrativeRoles,
    selectedPractitionerRoles,
    onAdministrativeRoleChange,
    onPractitionerRoleChange,
    clearRoles,
  } = useRoleFilter({
    practitionerRole: initialValues.practitionerRoles,
    administrativeRole: initialValues.administrativeRoles,
  });
  const {
    careProviders,
    careUnits,
    selectedCareProviders,
    selectedCareUnits,
    onCareProviderChange,
    onCareUnitChange,
    clearCareProvidersAndCareUnitsSelection,
  } = useCareUnitsAndCareProviders({
    careUnitIds: initialValues.careUnitIds,
    careProviderIds: initialValues.careProviderIds,
  });

  const onClear = () => {
    setSearchString('');
    clearRoles();
    clearCareProvidersAndCareUnitsSelection();
  };

  const handleSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    setShowPopover(true);
  };

  const handleSearchFocus = () => {
    setShowPopover(true);
  };
  const handleSearchBlur = () => {
    setShowPopover(false);
  };

  useEffect(
    () => {
      props.onFilter({
        searchString,
        selectedAdministrativeRoles,
        selectedPractitionerRoles,
        selectedCareProviders,
        selectedCareUnits,
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedAdministrativeRoles,
      selectedPractitionerRoles,
      selectedCareProviders,
      selectedCareUnits,
    ]
  );

  const handleSearch = (
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLInputElement>,
    info?: {
      source?: 'clear' | 'input';
    }
  ) => {
    if (info?.source === 'clear') {
      setSearchString('');
    }
    if (searchString.trim().length < 2) {
      return;
    }
    setShowPopover(false);
    props.onFilter({
      searchString: value,
      selectedAdministrativeRoles,
      selectedPractitionerRoles,
      selectedCareProviders,
      selectedCareUnits,
    });
  };

  const handleEnterPress = () => {
    if (searchString.trim().length < 2) {
      return;
    }
    setShowPopover(false);

    props.onFilter({
      searchString,
      selectedAdministrativeRoles,
      selectedPractitionerRoles,
      selectedCareProviders,
      selectedCareUnits,
    });
  };

  return (
    <Form data-testid="filters" className="ant-form-vertical">
      <Flex gap={5} className={styles.container}>
        <div>
          {/* popover should show up when user starts typing or wiping, hides onblur and when user presses the search icon or enter */}
          <Popover
            placement="bottomLeft"
            open={showPopover}
            content={
              <div>
                <InfoCircleOutlined className={styles.icon} /> &nbsp;{' '}
                {intl.formatMessage({ id: 'Type at least 2 characters and press Enter' })}
              </div>
            }
          >
            <Form.Item
              label={intl.formatMessage({ id: 'Search user' })}
              className={styles.formItem}
            >
              <Search
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
                data-testid="practitioner-search-input"
                placeholder={intl.formatMessage({ id: 'Search by name or ID' })}
                value={searchString}
                onChange={handleSearchStringChange}
                onPressEnter={handleEnterPress}
                onSearch={handleSearch}
                enterButton={
                  <Button>
                    <SearchOutlined />
                  </Button>
                }
                allowClear
              />
            </Form.Item>
          </Popover>
        </div>

        <Form.Item label={intl.formatMessage({ id: 'Care provider' })} className={styles.formItem}>
          <Select
            data-testid="care-provider-dropdown"
            showSearch
            mode="multiple"
            optionFilterProp="label"
            maxTagCount={2}
            notFoundContent={<DefaultEmpty />}
            placeholder={intl.formatMessage({ id: 'Select care provider' })}
            options={careProviders}
            maxTagPlaceholder={maxTagPlaceholder}
            {...{
              value: selectedCareProviders,
              onChange: onCareProviderChange,
            }}
          />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'Care unit' })} className={styles.formItem}>
          <Select
            data-testid="care-unit-dropdown"
            showSearch
            mode="multiple"
            placeholder={intl.formatMessage({ id: 'Select care unit' })}
            optionFilterProp="label"
            maxTagCount={2}
            options={careUnits}
            notFoundContent={<DefaultEmpty />}
            maxTagPlaceholder={maxTagPlaceholder}
            {...{
              value: selectedCareUnits,
              onChange: onCareUnitChange,
            }}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'Practitioner role' })}
          className={styles.formItem}
        >
          <Select
            showSearch
            placeholder={intl.formatMessage({ id: 'Select role' })}
            data-testid="practitioner-role-dropdown"
            optionFilterProp="label"
            mode="multiple"
            maxTagCount={2}
            options={practitionerRoles}
            notFoundContent={<DefaultEmpty />}
            maxTagPlaceholder={maxTagPlaceholder}
            {...{
              value: selectedPractitionerRoles,
              onChange: onPractitionerRoleChange,
            }}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'Administrative role' })}
          className={styles.formItem}
        >
          <Select
            data-testid="select-role-dropdown"
            showSearch
            placeholder={intl.formatMessage({ id: 'Select role' })}
            optionFilterProp="label"
            mode="multiple"
            maxTagCount={2}
            options={administrativeRoles}
            notFoundContent={<DefaultEmpty />}
            maxTagPlaceholder={maxTagPlaceholder}
            {...{
              value: selectedAdministrativeRoles,
              onChange: onAdministrativeRoleChange,
            }}
          />
        </Form.Item>
        <Space align="center" className={styles.buttonHeight}>
          <Button
            onClick={onClear}
            data-testid="clear-button"
            type="text"
            className={styles.clearButton}
          >
            <FormattedMessage id="Clear filters" />
          </Button>
        </Space>
      </Flex>
    </Form>
  );
};
Filters.displayName = 'Filters';
export default Filters;
