import { RightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { styled } from 'styled-components';

import { useIsMainNavigationCollapsed } from '../MainNavigationContext';

interface MenuItemProps {
  icon?: React.ReactNode;
  label?: string | React.ReactNode;
  /**
   * Same as label, but would not be displayed in the tooltip in collapsed mode
   */
  antiLabel?: React.ReactNode;
  collapsedView?: React.ReactNode;
  onClick?: () => void;
  onEnterPress?: () => void;
  selected?: boolean;
  linkTo?: string;
  subMenu?: React.ReactNode;
}

export const MainNavigationMenuItem = ({
  antiLabel,
  label,
  icon,
  onClick,
  onEnterPress,
  collapsedView,
  selected,
  linkTo,
  subMenu,
  ...rest
}: MenuItemProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'>) => {
  const isCollapsed = useIsMainNavigationCollapsed();

  const history = useHistory();

  const isActive = selected || !!(linkTo && history.location.pathname.startsWith(linkTo));

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }

    if (linkTo) {
      history.push(linkTo);
    }
  }, [history, linkTo, onClick]);

  const handleEnterPress = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        if (onEnterPress) {
          onEnterPress();
        }

        if (linkTo) {
          history.push(linkTo);
        }
      }
    },
    [history, linkTo, onClick]
  );

  const interactive = !!onClick || !!linkTo || !!subMenu;

  const [isHovered, setIsHovered] = useState(false);
  const delayedHover = useRef<boolean>(false);

  const onHover = useCallback(() => {
    setIsHovered(true);
    delayedHover.current = true;
  }, []);

  const unHover = useCallback(() => {
    delayedHover.current = false;
    setTimeout(() => {
      if (!delayedHover.current) {
        setIsHovered(false);
      }
    }, 200);
  }, []);

  return (
    <Tooltip
      open={isCollapsed ? undefined : false}
      trigger={isCollapsed ? ['hover'] : []}
      title={label}
      placement="right"
    >
      <ItemWrap
        {...rest}
        $selected={isActive}
        $isCollapsed={isCollapsed}
        onClick={handleClick}
        onKeyDown={handleEnterPress}
        $interactive={interactive}
        onMouseEnter={onHover}
        onMouseLeave={unHover}
        role={interactive ? 'button' : undefined}
      >
        {collapsedView && <ItemAlternative>{collapsedView}</ItemAlternative>}
        {icon && <ItemIcon>{icon}</ItemIcon>}
        {label && <ItemLabel>{label}</ItemLabel>}
        {antiLabel && <ItemLabel>{antiLabel}</ItemLabel>}
        {subMenu && <SubmenuIcon />}
        {isHovered && !!subMenu && subMenu}
      </ItemWrap>
    </Tooltip>
  );
};

//#region Elements

const ItemIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  font-size: ${18 / 16}rem;
  transition:
    margin 0.2s ease,
    transform 0.3s ease;
`;

const ItemLabel = styled.div`
  font-size: 0.875rem;
  white-space: nowrap;
  transition: opacity 0.2s ease;
  flex: 1;
`;

const ItemAlternative = styled.div`
  font-size: 0.875rem;
  white-space: nowrap;
  width: 4rem;
  min-width: 4rem;
  /* margin-left: -1rem; */
  margin-left: -6rem;
  margin-right: 2rem;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemWrap = styled.div<{ $interactive: boolean; $isCollapsed: boolean; $selected?: boolean }>`
  display: flex;
  align-items: center;
  /* width: 100%; */
  /* padding-left: 1.5rem; */

  margin: 0.25rem 0.5rem 0;
  /* padding: 0.6rem 1.3rem; */
  padding-left: 0.5rem;

  height: ${40 / 16}rem;
  border-radius: 0.4rem;

  background-color: ${({ $selected }) => ($selected ? 'var(--color-teal-light)' : 'white')};
  color: ${({ $selected }) => ($selected ? 'var(--color-teal)' : 'var(--color-text-primary)')};
  transition:
    background-color 0.2s ease,
    color 0.2s ease;

  ${({ $interactive }) =>
    $interactive &&
    `
    cursor: pointer;
    &:hover {
      background-color: var(--color-teal-light);
      color: var(--color-teal);
    }
  `}

  &:has(${ItemAlternative}) {
    ${ItemIcon} {
      opacity: ${({ $isCollapsed }) => ($isCollapsed ? 0 : 1)};
      ${({ $isCollapsed }) => $isCollapsed && 'pointer-events: none;'}
    }

    ${ItemLabel} {
      ${({ $isCollapsed }) => $isCollapsed && 'pointer-events: none;'}
    }
  }

  ${({ $isCollapsed }) =>
    `
      ${ItemLabel} {
        opacity: ${$isCollapsed ? 0 : 1};
      } 

      ${ItemIcon} {
        margin-left: ${$isCollapsed ? '0.9rem' : '0'};
        transform: scale(${$isCollapsed ? 1.1 : 1});
      }

      ${ItemAlternative} {
        transform: translateX(${$isCollapsed ? '5.5rem' : '0'});
        opacity: ${$isCollapsed ? 1 : 0};
      }
    `}
`;

const SubmenuIcon = styled(RightOutlined)`
  margin-right: 0.5rem;
`;
