import {
  EditOutlined,
  PlusCircleOutlined,
  DownOutlined,
  UpOutlined,
  MedicineBoxOutlined,
} from '@ant-design/icons';
import { Button, Table, Input, Form, Space, Switch, Tooltip, Flex } from 'antd';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { SortOrder } from 'antd/lib/table/interface';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment, HtmlHTMLAttributes } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps, Link } from 'react-router-dom';

import { PartnerCareProvider } from 'api/partnersApi';
import { ReactComponent as HospitalOutlined } from 'assets/svg/HospitalOutlined.svg';
import PageHeader from 'components/PageHeader';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { BASIC_DETAILS_FORM_FIELDS as FIELDS } from 'constants/origins';
import RootStoreContext from 'context/RootStoreContext';
import PartnerStatus from 'modules/PartnerStatus';
import RootStore from 'stores/RootStore';

import styles from './CareProvidersList.module.css';
import { calculateTotalItems, calculateTotalItemsByType } from './careProviderUtils';
import CareProvidersListStore from './stores/CareProvidersListStore';
import type { CareProvidersListItem } from './stores/types';

interface Props extends RouteComponentProps<{ careProviderId: string }>, WrappedComponentProps {}

@observer
class CareProvidersList extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;
  declare state: Readonly<{ pageSize: number; isAccessibleToMe: boolean; searchTerm: string }>;
  careProvidersListStore: CareProvidersListStore;

  columns = [
    {
      title: <FormattedMessage id="basic-details-form.name-label" />,
      dataIndex: FIELDS.NAME,
      defaultSortOrder: 'ascend' as SortOrder,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      minWidth: 400,
      render: (text: string, record: CareProvidersListItem) =>
        record.canView ? (
          <Link
            className={styles.link}
            data-testid={
              !record.isCareProvider
                ? `/health-care-organizations/${record.careProviderId}/care-units/${record.id}/basic-details`
                : `/health-care-organizations/${record.id}/`
            }
            to={
              !record.isCareProvider
                ? `/health-care-organizations/${record.careProviderId}/care-units/${record.id}/basic-details`
                : `/health-care-organizations/${record.id}/`
            }
          >
            {record.isCareProvider ? (
              <MedicineBoxOutlined className={styles.careUnitIcon} />
            ) : (
              <HospitalOutlined
                className={styles.careUnitIconHospital}
                width={18}
                viewBox="0 0 24 25"
              />
            )}
            <span style={{ marginLeft: 4 }}>{text}</span>
          </Link>
        ) : (
          <Tooltip
            title={this.props.intl.formatMessage({
              id: record.isCareProvider
                ? "You don't have access to edit or view details for this care provider"
                : "You don't have access to edit or view details for this care unit",
            })}
          >
            <span>{text}</span>
          </Tooltip>
        ),
      width: 400,
    },
    {
      title: <FormattedMessage id="ID" />,
      dataIndex: FIELDS.ID,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      ellipsis: true,
      render: (_: string, record: CareProvidersListItem) => {
        const id = record.externalOrgId || record.id;
        return id?.length > 36 ? ( // will be truncated if longer than 36 characters
          <Tooltip title={id}>
            <span className={styles.textTruncate}>{id}</span>
          </Tooltip>
        ) : (
          id
        );
      },
    },
    {
      title: <FormattedMessage id="Type" />,
      dataIndex: FIELDS.ID,
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (_: string, record: CareProvidersListItem) => {
        return !record.isCareProvider ? (
          <FormattedMessage id="Care unit" />
        ) : (
          <FormattedMessage id="Care provider" />
        );
      },
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      width: 120,
      render: (_: string, record: CareProvidersListItem) => {
        return (
          <Fragment>
            {/* Currently editing is only enabled on care unit level / children */}
            {!record.children && record.canView && (
              <Button
                type="link"
                icon={<EditOutlined />}
                data-testid={`can-view-care-providers-${record.careProviderId}-care-units-${record.id}-basic-details`}
                onClick={() =>
                  this.props.history.push(
                    `/health-care-organizations/${record.careProviderId}/care-units/${record.id}/basic-details`
                  )
                }
              />
            )}

            {/* Currently editing on top level... care provider level. */}
            {record.children && (
              <Flex align="end" justify="end" data-testid="actions">
                {(this.context.userPermissionsStore.canEditCurrentPartner || record.canView) && (
                  <>
                    <Button
                      type="link"
                      icon={<EditOutlined />}
                      data-testid={`/health-care-organizations/${record.id}/announcements`}
                      onClick={() => {
                        this.props.history.push(
                          `/health-care-organizations/${record.id}/announcements`
                        );
                      }}
                    />
                    <Button
                      type="link"
                      data-testid={`/health-care-organizations/${record.id}/care-units/add`}
                      icon={<PlusCircleOutlined />}
                      onClick={() =>
                        this.props.history.push(
                          `/health-care-organizations/${record.id}/care-units/add`
                        )
                      }
                    />
                  </>
                )}
                <Button
                  type="link"
                  data-testid={`care-provider-caret-${record.id}`}
                  icon={
                    this.careProvidersListStore.expandedRowKeys.has(record.id) ? (
                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )
                  }
                  onClick={this.handleOnExpand(record)}
                />
              </Flex>
            )}
          </Fragment>
        );
      },
    },
  ];

  constructor(props: Props, context: RootStore) {
    super(props);
    this.careProvidersListStore = context.careProvidersListStore;
    this.state = {
      pageSize: 10,
      isAccessibleToMe: true,
      searchTerm: this.careProvidersListStore.searchTerm || '',
    };
  }
  componentDidMount(): void {
    this.careProvidersListStore.init();
  }

  handleWhatIsAccessibleToMe = (isAccessibleToMe: boolean): void => {
    this.setState({ isAccessibleToMe });
  };

  handleOnExpand = (record: PartnerCareProvider) => () => {
    const expanded = !this.careProvidersListStore.expandedRowKeys.has(record.id);
    this.careProvidersListStore.handleExpand(expanded, record.id);
  };

  handlePaginationChange = (currentPagination: TablePaginationConfig) => {
    this.setState({ pageSize: currentPagination.pageSize });
  };

  onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: e.target.value });
  };

  handleEnterPress = () => {
    this.careProvidersListStore.handleSearchChange(this.state.searchTerm);
  };

  handleSearch = (
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLInputElement>,
    info?: {
      source?: 'clear' | 'input';
    }
  ) => {
    if (info?.source === 'clear') {
      this.setState({ searchTerm: '' });
      this.careProvidersListStore.handleSearchChange('');
    } else {
      this.careProvidersListStore.handleSearchChange(this.state.searchTerm);
    }
  };

  render() {
    const { partnersStore, careUnitsStore } = this.context;
    const dataSource = this.state.isAccessibleToMe
      ? this.careProvidersListStore.getCareProviderListWithPermissionsAccessibleToUser
      : this.careProvidersListStore.careProviderListWithPermissionsAttached;
    // const totalItems = calculateTotalItems(dataSource);
    const totals = calculateTotalItemsByType(dataSource);
    return (
      <Fragment>
        <PartnerStatus />
        <div className={styles.container}>
          <PageHeader
            content="health-care"
            breadcrumbs={[
              {
                text: <FormattedMessage id="Start" />,
                link: '/',
              },
              {
                text: (
                  <span>
                    <FormattedMessage id="Organization" />: {partnersStore.currentPartner?.id}
                  </span>
                ),
              },
              {
                text: <FormattedMessage id="health-care" />,
              },
            ]}
          />
          <Space className={styles.inputContainer}>
            <Form.Item
              label={this.props.intl.formatMessage({ id: 'Search care provider or care unit' })}
              colon={false}
              layout="vertical"
            >
              <Input.Search
                placeholder={this.props.intl.formatMessage({ id: 'Search name or HSA ID' })}
                onChange={this.onSearchTextChange}
                onPressEnter={this.handleEnterPress}
                onSearch={this.handleSearch}
                className={styles.search}
                value={this.state.searchTerm}
                data-testid="clinic-search-input"
                allowClear
              />
            </Form.Item>
            <Form.Item
              className={styles.switch}
              label={this.props.intl.formatMessage({ id: 'Only show accessible to me' })}
              colon={false}
            >
              <Switch
                checked={this.state.isAccessibleToMe}
                onChange={this.handleWhatIsAccessibleToMe}
                data-testid="clinic-has-access-to-me-input"
              />
            </Form.Item>
          </Space>
          <Table<CareProvidersListItem>
            tableLayout="auto"
            columns={this.columns}
            dataSource={dataSource}
            pagination={{
              pageSize: this.state.pageSize,
              total: dataSource.length,
              showTotal: () =>
                this.props.intl.formatMessage({ id: 'total-care-providers-care-units' }, totals),
            }}
            onChange={this.handlePaginationChange}
            onRow={record =>
              ({
                'data-testid': record.isCareProvider
                  ? `care-provider-${record.id}`
                  : `care-unit-${record.id}`,
                className: !record.isCareProvider ? styles.careUnitRow : '',
              }) as HtmlHTMLAttributes<HTMLElement>
            }
            rowKey={FIELDS.ID}
            expandable={{
              expandIcon: () => false,
              expandedRowKeys: Array.from(this.careProvidersListStore.expandedRowKeys.toJS()),
              indentSize: 28,
            }}
            data-testid="care-providers-list"
            className={styles.table}
            loading={partnersStore.isLoading() || careUnitsStore.isLoading()}
          />
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(CareProvidersList);
