import { SearchOutlined } from '@ant-design/icons';
import { Table, TableColumnsType, Tooltip, Typography, Empty } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import styles from './empty.module.css';
import { UserTableDataType, TableChange } from '../types';

interface Props {
  data: UserTableDataType[];
  onChange: TableChange;
  loading: boolean;
  onRow?: any;
  isNetworkError?: boolean;
  isEmptyFilter?: boolean;
  isEmptyData?: boolean;
  meta: {
    pageSize: number;
    total: number;
    current?: number;
    showTotal: () => string;
  };
}

const UsersTable = ({
  data,
  onChange,
  loading,
  meta,
  onRow,
  isNetworkError,
  isEmptyData,
  isEmptyFilter,
}: Props) => {
  const intl = useIntl();
  const columns: TableColumnsType<UserTableDataType> = [
    {
      title: intl.formatMessage({ id: 'Name' }),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sorter: true,
      render: (text: string, record) => (
        <Link to={`/roles/${record.key}/edit`} data-testid={`/roles/${record.key}/edit`}>
          {text}
        </Link>
      ),
    },
    {
      title: intl.formatMessage({ id: 'ID' }),
      dataIndex: 'ids',
      key: 'ids',
      ellipsis: true,
      render: (text: string) => text,
    },
    {
      title: intl.formatMessage({ id: 'Care unit' }),
      dataIndex: 'careUnits',
      key: 'careUnits',
      ellipsis: true,
      render: (text: string) => text,
    },
    {
      title: intl.formatMessage({ id: 'Role' }),
      dataIndex: 'roles',
      key: 'roles',
      ellipsis: true,
      showSorterTooltip: true,
      render: (text: string) => {
        const translatedRoles =
          text !== '–'
            ? text
                .split(',')
                .map((role: string) => intl.formatMessage({ id: `roles.${role.trim()}` }))
                .join(', ')
            : text;
        return translatedRoles;
      },
    },
    {
      title: intl.formatMessage({ id: 'Latest online' }),
      dataIndex: 'latestLogin',
      key: 'latestLogin',
      sorter: true,
      width: 200,
      render: (latestLogin: string) =>
        latestLogin !== '–' ? (
          latestLogin
        ) : (
          <Tooltip
            title={intl.formatMessage({ id: 'Latest Clinic login information is unavailable' })}
          >
            <span>{latestLogin}</span>
          </Tooltip>
        ),
    },
  ];

  return (
    <Table
      key="id"
      data-testid="user-table"
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={meta}
      locale={{
        emptyText: (
          <Empty
            className={styles['ant-empty-normal']}
            description={
              isNetworkError ? (
                <Typography.Title level={4} className={styles.title}>
                  {intl.formatMessage({
                    id: 'Unable to load data',
                  })}
                </Typography.Title>
              ) : isEmptyFilter ? (
                <Typography.Title level={4} className={styles.title}>
                  {intl.formatMessage({ id: 'Search to display results' })}
                </Typography.Title>
              ) : isEmptyData ? (
                <Typography.Title level={4} className={styles.title}>
                  {intl.formatMessage({ id: 'No users found' })}
                </Typography.Title>
              ) : (
                <Typography.Title level={4} className={styles.title}>
                  {intl.formatMessage({ id: 'No data' })}
                </Typography.Title>
              )
            }
            imageStyle={{ height: 40 }}
            image={
              !isNetworkError && isEmptyFilter ? (
                <SearchOutlined
                  style={{
                    color: '#6b88b5',
                    fontSize: '2.5rem',
                  }}
                />
              ) : (
                Empty.PRESENTED_IMAGE_SIMPLE
              )
            }
          >
            {isNetworkError ? (
              <Typography.Text>
                {intl.formatMessage({
                  id: 'We were unable to load data. Try refreshing the page or come back later',
                })}
              </Typography.Text>
            ) : isEmptyFilter ? (
              <Typography.Text>
                {intl.formatMessage({ id: 'Find users by searching or applying filters above' })}
              </Typography.Text>
            ) : isEmptyData ? (
              <Typography.Text>
                {intl.formatMessage({ id: 'Try modifying the filters' })}
              </Typography.Text>
            ) : (
              <Typography.Text>{intl.formatMessage({ id: 'No data' })}</Typography.Text>
            )}
          </Empty>
        ),
      }}
      onRow={onRow}
      onChange={onChange}
    />
  );
};

export default UsersTable;
