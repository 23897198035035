import { GlobalOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { LANGS } from 'constants/enums';
import { useLang } from 'context/IntlContext';
import { getValueOfFeatureFlag } from 'utils/appUtils';

import { MainNavigationMenuItem } from './MainNavigationMenuItem';

export const LanguageSelection = () => {
  const { switchLang, lang } = useLang();

  const isDanishEnabled = getValueOfFeatureFlag('FF_ENABLE_DANISH_TRANSLATION');
  const languageOptions = useMemo(
    () =>
      Object.values(LANGS).filter(language =>
        !isDanishEnabled && language === LANGS.DA ? false : true
      ),
    [isDanishEnabled]
  );

  return (
    <MainNavigationMenuItem
      icon={<GlobalOutlined />}
      role="menuitem"
      antiLabel={
        <Select tabIndex={0} style={{ width: '90%' }} value={lang} onChange={switchLang}>
          {languageOptions.map(option => (
            <Select.Option key={option} value={option}>
              <FormattedMessage id={`general.language-original-${option}`} />
            </Select.Option>
          ))}
        </Select>
      }
      collapsedView={
        <Select tabIndex={0} value={lang} onChange={switchLang}>
          {languageOptions.map(option => (
            <Select.Option key={option} value={option}>
              {option.toUpperCase()}
            </Select.Option>
          ))}
        </Select>
      }
    />
  );
};
