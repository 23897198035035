import { observable, action, runInAction, IObservableArray, toJS } from 'mobx';
import { IntlShape } from 'react-intl';

import { notification } from 'services/NotificationService';
import RootStore from 'stores/RootStore';

import {
  NewsItem,
  PartnerId,
  fetchNewsItems,
  updateNewsItems,
  createNewsItem,
  deleteNewsItem,
  reorderNewsItems,
} from '../api/newsItemsApi';

export default class AlertsStore {
  @observable isLoading = false;
  newsItems: IObservableArray<NewsItem> = observable.array([]);

  @observable activeItem?: NewsItem;

  constructor(
    private rootStore: RootStore,
    private intl: IntlShape,
    private originId: string,
    private partnerId: PartnerId = rootStore.partnersStore.currentPartner?.id
  ) {}

  @action
  fetchNewsItems = async () => {
    try {
      this.isLoading = true;
      const { data } = await fetchNewsItems(this.originId, this.partnerId);

      runInAction(() => {
        this.newsItems.replace(data);
      });
      /* eslint-disable no-empty */
    } catch {
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleReordering = async (newsItems: NewsItem[]) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      runInAction(() => {
        this.newsItems.replace(newsItems);
      });

      const data = newsItems.map((item, index) => {
        return {
          newsId: item.id,
          rank: index + 1,
        };
      });

      await reorderNewsItems(data, this.originId, this.partnerId);
      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'origin.alerts.alerts-updated' }),
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleCreateData = async (newsItem: NewsItem) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      await createNewsItem(newsItem, this.originId, this.partnerId);
      const { data } = await fetchNewsItems(this.originId, this.partnerId);

      runInAction(() => {
        this.newsItems.replace(data);
      });
      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'patient-app.alert.new-alert-added' }),
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleEditData = async (newsItem: NewsItem) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const newNewsItems = toJS(this.newsItems);
      const newNewsItemIndex = this.newsItems.findIndex(item => item.id === newsItem.id);

      if (newNewsItemIndex === -1) {
        throw new Error(`No item with index ${newNewsItemIndex}`);
      }

      await updateNewsItems(newsItem, this.originId, newsItem.id, this.partnerId);

      newNewsItems[newNewsItemIndex] = newsItem;

      runInAction(() => {
        this.newsItems.replace(newNewsItems);
      });

      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'patient-app.alert.alert-edit' }),
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  handleDeleteData = async (id: string) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const newNewsItems = this.newsItems.filter(item => item.id !== id);

      await deleteNewsItem(id, this.originId, this.partnerId);

      runInAction(() => {
        this.newsItems.replace(newNewsItems);
      });

      notification.success({
        placement: 'top',
        message: this.intl.formatMessage({ id: 'patient-app.alert.alert-removed' }),
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  handleEdit = (id: string) => {
    this.activeItem = this.newsItems.find(item => item.id === id);
  };

  @action
  handleAdd = () => {
    this.activeItem = {
      subject: '',
      body: '',
      link: '',
      id: '',
    };
  };

  @action
  handleCancel = () => {
    this.activeItem = undefined;
  };

  handleSubmit = async (newNewsItem: NewsItem, formType: string) => {
    Object.entries(newNewsItem).forEach(([key, value]) => {
      if (value === '') {
        newNewsItem[key] = null;
      }
    });

    try {
      if (formType === 'add') {
        await this.handleCreateData(newNewsItem);
      } else {
        await this.handleEditData(newNewsItem);
      }
      runInAction(() => {
        this.activeItem = undefined;
      });
      /* eslint-disable no-empty */
    } catch {}
  };
}
