import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Select, Button, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Observer } from 'mobx-react';
import { stringify } from 'query-string';
import React, { useContext, useState, useEffect, Fragment, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';
import { setPartnerId } from 'utils/tokenUtils';

const SelectPartner = () => {
  const { partnersStore, authStore } = useContext(RootStoreContext);
  const [selectedPartnerId, setSelectedPartnerId] = useState<string | null>(null);
  const history = useHistory();

  const handleSubmit = useCallback(
    async (values: Store) => {
      const { partnerId } = values;

      await authStore.partnerSwitch(partnerId);
      setSelectedPartnerId(partnerId);
    },
    [authStore]
  );

  useEffect(() => {
    if (!authStore.isAuthenticated()) {
      history.push('/login');
    }
  });

  useEffect(() => {
    if (partnersStore.partners.length === 1) {
      // when only 1 partner available, use it as default and skip the selection step
      setSelectedPartnerId(partnersStore.partners[0].id);
    } else if (partnersStore.partners.length === 0) {
      authStore.logout({
        errorMessage: stringify({ messageKey: 'partners.errors.no-partners-found' }),
      });
    }
  }, [partnersStore.partners]);

  useEffect(() => {
    if (selectedPartnerId) {
      setPartnerId(selectedPartnerId);
    }
  }, [selectedPartnerId, partnersStore]);

  if (selectedPartnerId) {
    return <Redirect to="/" />;
  }

  return (
    <Observer>
      {() => (
        <Fragment>
          {partnersStore.isLoading() || !partnersStore.partners.length ? (
            <Spin size="large" indicator={<LoadingOutlined spin />} />
          ) : (
            <Form onFinish={handleSubmit} layout="vertical">
              <Form.Item label={<FormattedMessage id="partners.select-partner" />} name="partnerId">
                <Select
                  data-testid="partner-dropdown"
                  showSearch
                  options={partnersStore.partnersAsSelectOptions}
                  virtual={false}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                  data-testid="submit-partner-btn"
                >
                  <FormattedMessage id="general.confirm" />
                </Button>
              </Form.Item>
            </Form>
          )}
        </Fragment>
      )}
    </Observer>
  );
};

export default SelectPartner;
