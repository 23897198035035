import { useFormik } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { Practitioner } from 'api/practitionerApi';
import {
  IDENTITIES,
  NORWEGIAN_PERSONAL_IDENTITY_NUMBER_LENGTH,
  SWEDISH_PERSONAL_IDENTITY_NUMBER_LENGTH,
  DANISH_PERSONAL_IDENTITY_NUMBER_LENGTH,
} from 'constants/practitioner';
import {
  LETTERS_NUMBERS_SPACES_DASHES_REGEX,
  NUMBERS_REGEX,
  LETTERS_NUMBERS_SPACES_DASHES_AND_DOTS_REGEX,
} from 'constants/regex';

interface Props {
  initialValues: Practitioner & { countryCode: string };
  onSubmit: (values: Practitioner & { countryCode: string }) => void;
}

export const usePractitionerFormik = ({ initialValues, onSubmit }: Props) => {
  const intl = useIntl();
  const numbersValidationErrorMessage = intl.formatMessage({
    id: 'general.errors.numbers-validation',
  });
  const requiredErrorMessage = intl.formatMessage({ id: 'general.errors.required' });

  return useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: React.useMemo(
      () =>
        Yup.object().shape({
          givenName: Yup.string()
            .matches(LETTERS_NUMBERS_SPACES_DASHES_REGEX, () =>
              intl.formatMessage({
                id: 'general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation',
              })
            )
            .required(requiredErrorMessage),
          middleAndSurname: Yup.string()
            .matches(LETTERS_NUMBERS_SPACES_DASHES_REGEX, () =>
              intl.formatMessage({
                id: 'general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation',
              })
            )
            .required(requiredErrorMessage),
          title: Yup.string()
            .matches(LETTERS_NUMBERS_SPACES_DASHES_AND_DOTS_REGEX, () =>
              intl.formatMessage({
                id: 'general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation',
              })
            )
            .required(requiredErrorMessage),
          mobileNumber: Yup.string()
            .test({
              name: 'isValidPhoneNumber',
              test: function (val) {
                // "notRequired" neither "nullable" is working as expected
                // extra condition needed
                if (!val) {
                  return true;
                }
                // note, has to be "function" to have access to "this"
                const countryCode = this.parent.countryCode;
                const isValid = isValidPhoneNumber(countryCode + val);
                return isValid;
              },
              message: intl.formatMessage({ id: 'basic-details-form.wrong-value-error' }),
            })
            .notRequired()
            .nullable(),
          email: Yup.string()
            .email(intl.formatMessage({ id: 'general.email-validation-error' }))
            .notRequired()
            .nullable(),
          externalIds: Yup.array().of(
            Yup.object().shape({
              externalId: Yup.string()
                .required(requiredErrorMessage)
                .when('externalIdType', {
                  is: IDENTITIES.SWEDISH_PERSONAL_IDENTITY_NUMBER,
                  then: Yup.string()
                    .required(requiredErrorMessage)
                    .test({
                      name: 'isExactLength',
                      test: val => val?.length === SWEDISH_PERSONAL_IDENTITY_NUMBER_LENGTH,
                      message: intl.formatMessage(
                        { id: 'general.errors.exact-length' },
                        { length: SWEDISH_PERSONAL_IDENTITY_NUMBER_LENGTH }
                      ),
                    })
                    .matches(NUMBERS_REGEX, numbersValidationErrorMessage),
                })
                .when('externalIdType', {
                  is: IDENTITIES.DANISH_PERSONAL_IDENTITY_NUMBER,
                  then: Yup.string()
                    .required(requiredErrorMessage)
                    .test({
                      name: 'isExactLength',
                      test: val => val?.length === DANISH_PERSONAL_IDENTITY_NUMBER_LENGTH,
                      message: intl.formatMessage(
                        { id: 'general.errors.exact-length' },
                        { length: DANISH_PERSONAL_IDENTITY_NUMBER_LENGTH }
                      ),
                    })
                    .matches(NUMBERS_REGEX, numbersValidationErrorMessage),
                })
                .when('externalIdType', {
                  is: IDENTITIES.EMAIL,
                  then: Yup.string()
                    .required(requiredErrorMessage)
                    .email(intl.formatMessage({ id: 'general.email-validation-error' })),
                })
                .when('externalIdType', {
                  is: IDENTITIES.NORWEGIAN_PERSONAL_IDENTITY_NUMBER,
                  then: Yup.string()
                    .required(requiredErrorMessage)
                    .test({
                      name: 'isExactLength',
                      test: val => val?.length === NORWEGIAN_PERSONAL_IDENTITY_NUMBER_LENGTH,
                      message: intl.formatMessage(
                        { id: 'general.errors.exact-length' },
                        { length: NORWEGIAN_PERSONAL_IDENTITY_NUMBER_LENGTH }
                      ),
                    })
                    .matches(NUMBERS_REGEX, numbersValidationErrorMessage),
                })
                .when('externalIdType', {
                  is: IDENTITIES.HSA_ID,
                  then: Yup.string()
                    .required(requiredErrorMessage)
                    .matches(LETTERS_NUMBERS_SPACES_DASHES_REGEX, () =>
                      intl.formatMessage({
                        id: 'general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation',
                      })
                    ),
                })
                .when('externalIdType', {
                  // field disabled in edit mode. ie. no need validating.
                  is: IDENTITIES.INTERNAL_IDP && !initialValues?.id,
                  then: Yup.string()
                    .required(requiredErrorMessage)
                    .email(intl.formatMessage({ id: 'general.email-validation-error' })),
                }),
              externalIdType: Yup.string().required(requiredErrorMessage),
            })
          ),
        }),

      [initialValues.id]
    ),
  });
};
